<template>
  <div>
    <s-toolbar label="Ubicaciones de Artículos" @selectedArticule=selectedArticle($event) :search="false" edit addNoFunction @edit="edit($event)" />
    <v-card>
      <v-col>
        <v-container>
          <v-row>
            <v-col lg="10">
              <v-row>
                <v-col lg="6" cols=12 class="s-col-form">
                  <s-article-search v-model="article" @selected="  seletedData($event)"></s-article-search>
                </v-col>
                <v-col lg="2" cols=12 class="s-col-form">
                  <s-text disabled label="Cod articulo" v-model="item.ArtCode"></s-text>
                </v-col>
                <v-col lg="2" cols=12 class="s-col-form">
                  <s-text disabled label="Stock" v-model="item.ArtStock"></s-text>
                </v-col>
                <v-col lg="2" cols=12 class="s-col-form">
                  <s-text disabled label="Costo del Artículo" v-model="item.ArtAvgPrice"></s-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="2" cols=12 class="s-col-form">
                  <s-text disabled label="Grupo Artículos" v-model="item.ArtGroupName"></s-text>
                </v-col>
                <v-col lg="3" cols=12 class="s-col-form">
                  <s-text disabled   label="Grupo " v-model="item.UserArtGroupName"></s-text>
                </v-col>
                <v-col lg="3" cols=12 class="s-col-form">
                  <s-text disabled label="Sub-Grupo" v-model="item.UserArtSubGroupName"></s-text>
                </v-col>
                <v-col lg="1" cols=6 class="s-col-form">
                  <s-text disabled label="U.M" v-model="item.UnitOfMeasuarement"></s-text>
                </v-col>
                <v-col lg="1" cols=6 class="s-col-form">
                  <s-text disabled label="U.SUNAT" v-model="item.UserUnitOfMeasuarement"></s-text>
                </v-col>
                <v-col lg="2" cols=12 class="s-col-form">
                  <s-text disabled label="Indicador Impuestos" v-model="item.TaxCodeAP"></s-text>
                </v-col>
              </v-row>
            </v-col>

            <v-col lg="2" cols=12>
              <v-row>
                <v-col  class="s-col-form" cols=12>
                  <s-text disabled label="UBICACIÓN 01" v-model="item.UserPhysicalLocation"></s-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="s-col-form" cols=12>
                  <s-text disabled label="UBICACIÓN 02" v-model="item.LocPhysicalLocation"></s-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <v-col lg="6"> <s-crud no-search no-footer title="Últimas Entradas" :config="configE" :filter="filterInput" ref=" "/></v-col>

            <v-col lg="6"> <s-crud no-search no-footer title="Últimas Salidas" :config="configS" :filter="filterOutput"/></v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-card>
    <v-dialog v-model="dialog" width="60%" persistent transition="dialog-bottom-transition">
      <s-toolbar close :label="articletemp.ArtName" @close="dialog = false"> </s-toolbar>
      <v-card>
        <v-container>
          <v-row>
            <v-col lg="6" class="s-col-form">
              <s-select
                v-model="articletemp.UserPhysicalLocation"
                hide-details
                dense
                item-value="GenHelper"
                label="Ubicacion 01"
                item-text="GenDescription"
                clearable
                :items="itemsPhysicalLocation"
              ></s-select>
            </v-col>
            <v-col lg="6" class="s-col-form">
              <s-select
                v-model="articletemp.LocPhysicalLocation"
                hide-details
                dense
                item-value="GenHelper"
                label="Ubicacion 02"
                clearable
                item-text="GenDescription"
                :items="itemsLocPhysicalLocation"
              ></s-select>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="justify-center">
          <v-btn dark color="primary" @click="save($event)" block>Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SArticleSearch from "../../../components/Utils/Logistics/SArticleSearch.vue";
import _sArticleInput from "../../../services/Logistics/ConsultingSap/ArticleInputService.js";
import _sArticleOutput from "../../../services/Logistics/ConsultingSap/ArticleOutputService.js";
import SToolbar from "../../../components/Utils/SToolbar.vue";
import _sLocation from "../../../services/General/GenericService.js";
import _sArticle from "../../../services/Logistics/ArticleService.js";
export default {
  components: { SArticleSearch, SToolbar },
  data() {
    return {
      article: null,
      item: {
        ArtStock: "",
      },
      filterInput: { ArtCode: "j" },
      filterOutput: { ArtCode: "h" },

      configE: {
        service: _sArticleInput,
        model: {
          InpDateRequeriment: "date",
          InpDocDate: "date",
          InpDocNum: "ID",
        },
        headers: [
          {
            text: "Requerimiento",
            value: "InpNumRequeriment",
            sortable: false,
          },
          { text: "F. Req", value: "InpDateRequeriment", sortable: true },
          { text: "F. Entrada", value: "InpDocDate", sortable: false },
          { text: "Cant", value: "InpQuantity", sortable: true },
          { text: "Precio", value: "InpPrice", sortable: true },
        ],
      },
      configS: {
        service: _sArticleOutput,
        model: {
          OutDocNum: "ID",
          OutDocDate: "date",
          OutDateRequeriment: "date",
        },
        headers: [
          {
            text: "Requerimiento",
            value: "OutNumRequeriment",
            sortable: false,
          },
          { text: "F. Req", value: "OutDateRequeriment", sortable: true },
          { text: "F. Entrada", value: "OutDocDate", sortable: false },
          { text: "Cant", value: "OutQuantity", sortable: true },
          { text: "Precio", value: "OutPrice", sortable: true },
        ],
      },
      dialog: false,
      itemsPhysicalLocation: [],
      itemsLocPhysicalLocation: [],
      articletemp: {
        UserPhysicalLocation: null,
        LocPhysicalLocation: null,
        ArtCode: null,
        ArtName: null,
      },
    };
  },
  methods: {
    seletedData(art){;if(art){this.item=art
   this.filterInput.ArtCode = art.ArtCode;
          this.filterOutput.ArtCode = art.ArtCode;
    }},
    edit() {
      if (this.article != null) {
        this.dialog = true;
        this.articletemp.UserPhysicalLocation = this.article.UserPhysicalLocation;
        this.articletemp.LocPhysicalLocation = this.article.LocPhysicalLocation;
        this.articletemp.ArtCode = this.article.ArtCode;
        this.articletemp.ArtName = this.article.ArtName;
        this.LocationData();
      }
    },
    LocationData() {
      _sLocation.searchLocation("", this.$fun.getUserID(), true).then((r) => {
        console.log(r.data);
        this.itemsPhysicalLocation = r.data;
      });
      _sLocation.searchLocationSecondary("", this.$fun.getUserID(), false).then((r) => {
        console.log(r.data);
        this.itemsLocPhysicalLocation = r.data;
      });
    },
    save() {
      _sArticle.save(this.articletemp, this.$fun.getUserID()).then((r) => {
        if (r.status == 200) {
          this.article.UserPhysicalLocation = r.data.UserPhysicalLocation;
          this.article.LocPhysicalLocation = r.data.LocPhysicalLocation;
          console.log(r.data);
          this.$fun.alert("Actualizado Correctamente ", "success");
          this.dialog = false;
        }
      });
    },
    created() {},
    
  },
};
</script>
