import Service from "../../Service";
const resource = "article/";

export default {


  pagination(parameters, requestID) {
    return Service.post(resource + "entrada_articulos", parameters, {
      params: { requestID: requestID },
    });
  },
};